@import './styles/vars.scss';
@import './styles/theme.scss';

.App {
  text-align: center;
}

.snack_bar{
  border-radius: 9999px;
  background: var(--background-2);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
}
.snack_bar .success_icon{
  padding: 0.8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background: rgba(0, 180, 160, 0.12);
}
.snack_bar .error_icon{
  padding: 0.8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background: rgba(246, 61, 104, 0.12);
}
.snack_bar .snack_title{
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: var(--font-mine);
  font-size: 1.6vh;
  font-weight: 400;
  line-height: 2.4vh; /* 114.286% */
  letter-spacing: -0.26px;
}
.snack_bar .snack_text{
  margin: 0;
  color: var(--font-mine);
  font-family: 'Inter', sans-serif;
  font-size: 1.4vh;
  font-weight: 500;
  line-height: 1.6vh;
  letter-spacing: -0.26px;
}
.snack_alert{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2vh ;
  padding: 1.2vh;
}
.snack_bar .close_icon{
  padding: 0.6vh;
  border-radius: 999px;
  background: var(--buttons-alternative-default);
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin-right: 1vh;
}
.snack_bar .close_icon path{
  stroke: var(--font-mine);
}
.snack_bar .MuiAlert-action{
  padding: 0;
}
.snack_bar .MuiPaper-root{
  width: 45vh;
  padding: 1.2vh 2vh 1.2vh 1.2vh;
}
.snack_bar .MuiAlert-message , .MuiAlert-icon{
  padding: 0;
}