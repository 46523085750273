@import './vars.scss';
@import './theme.scss';

.paper {
    padding: 1.6vh 1.6vh 1.6vh 6.2vh;
}

.account_drawer {
    width: calc(43.6vh - 3.6vh);
    background-color: var(--background-2);
    position: relative;
    border-radius: 32px;
    border: 1px solid var(--border-main);
    @include flex(center, space-between);
    flex-direction: column;
    // height: 89vh;

    .close_icon {
        position: absolute;
        top: 0;
        left: -50px;
        padding: 1.2vh;
        @include flex(center, center);
        border-radius: var(--corner-full, 1000px);
        border: 1px solid var(--border-main);
        background: var(--background-2);
        cursor: pointer;

        path {
            stroke: var(--font-sub-font);
        }
    }

    .top_drawer {
        width: 100%;
        @include flex(center, start);
        flex-direction: column;

        .close_icon_mobile{
            display: none;
        }
        .drawer_header {
            width: calc(100% - 3.2vh);
            border-bottom: 1px solid var(--border-main);
            padding: 1.6vh;
            color: var(--font-mine);
            font-size: 1.4vh;
            font-weight: 600;
            line-height: 1.6vh;
            letter-spacing: 0.28px;
            @include flex(center, start);
            gap: 1.2vh;

            .icon {
                padding: 0.8vh;
                border-radius: 9999px;
                @include flex(center, center);
                background: var(--border-main, #26272B);

                ellipse {
                    stroke: var(--font-mine);
                }
            }
        }

        .drawer_body {
            width: calc(100% - 3.2vh);
            padding: 1.6vh;
            @include flex(center, start);
            flex-direction: column;
            gap: 1vh;

            .account_tabs {
                position: relative;
                background-color: #333;
                border-radius: 8px;
                padding: 0.2vh;
                background-color: var(--input-bg);
                gap: 0.4vh;
                @include flex(center, start);
                border-radius: 14px;
                width: 100%;
            }

            .tab {
                @include flex(start, space-between);
                flex: 1;
                padding: 1.2vh 1.6vh;
                padding-bottom: 4.8vh;
                gap: 0.8vh;
                font-family: $font;
                background-color: transparent;
                border: none;
                color: #b3b3b3;
                font-weight: 500;
                cursor: pointer;
                transition: color 0.3s;
                text-wrap: nowrap;
                z-index: 1;
                flex-direction: column;
                .top{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                &.active_buy {
                    color: #fcfcfc;
                    border-radius: 14px;
                    background: var(--Other-Green, #00B4A0);

                }
                &.active_sell {
                    color: #fcfcfc;
                    border-radius: 14px;
                    background:  #F63D68;

                }

                &:hover {
                    color: var(--font-mine);
                }
                &.active_buy:hover {
                    color: #fcfcfc;

                }
                &.active_sell:hover {
                    color: #fcfcfc;

                }
            }

            .background {
                position: absolute;
                top: 0.2vh;
                background-color: var(--buttons-alternative-switcher);
                border-radius: 2.4vh;
                transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
                z-index: 0;
            }

            .bottom_tabs {
                display: flex;
                padding: 2px;
                align-items: flex-start;
                gap: 2px;
                align-self: stretch;
                border-radius: 9999px;
                background: var(--input-bg, #131316);
    
                .tab {
                    background: var(--input-bg, #131316);
                    display: flex;
                    padding: 12px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    flex: 1 0 0;
                    border-radius: 9999px;
                    color: var(--font-sub-font, #51525C);
                    text-align: center;
                    font-family: 'Inter';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    border: none;
    
                    &.active {
                        background: var(--buttons-alternative-switcher, #1D1D20);
                        color: var(--font-mine, #FCFCFC);
                    }
                }
            }
            .price {
                display: flex;
                width: 100%;
                justify-content: space-between;

                .item {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    max-width: 48%;

                    label {
                        display: flex;
                        height: 16px;
                        flex-direction: column;
                        justify-content: center;
                        align-self: stretch;
                        color: var(--Font-Sub-Font, #51525C);
                        font-family: 'Inter';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.24px;
                    }

                    input {
                        box-sizing: border-box;
                        display: flex;
                        height: 48px;
                        padding: 8px 8px 8px 16px;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;
                        border-radius: var(--Corner-Full, 1000px);
                        border: 1px solid var(--border-main, #26272B);
                        background: var(--input-bg, #131316);
                        color: var(--font-mine, #FCFCFC);
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                }
            }

            .units_item {
                display: flex;
                flex-direction: column;
                gap: 4px;
                width: 100%;

                label {
                    display: flex;
                    height: 16px;
                    flex-direction: column;
                    justify-content: center;
                    align-self: stretch;
                    color: var(--font-sub-font, #51525C);
                    font-family: 'Inter';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.24px;
                }

                input {
                    box-sizing: border-box;
                    display: flex;
                    height: 48px;
                    padding: 8px 8px 8px 16px;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                    border-radius: var(--Corner-Full, 1000px);
                    border: 1px solid var(--border-main, #26272B);
                    background: var(--input-bg, #131316);
                    color: var(--font-mine, #FCFCFC);
                    font-family: 'Inter';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type=number] {
                    -moz-appearance: textfield;
                }
            }

            .volume_input {
                border: 1px solid var(--border-main);
                @include flex(center, center);
                padding: 1.2vh;
                border-radius: 1000px;
                width: calc(100% - 2.4vh - 2px);
                background: var(--input-bg);

                span {
                    @include flex(center, center);

                    path {
                        stroke: var(--font-sub-font);
                    }

                    circle {
                        stroke: var(--font-sub-font);
                    }
                }
                input {
                    outline: none;
                    border-radius: 1000px;
                    border: none;
                    background: none;
                    font-family: $font;
                    width: calc(100% - 2.4vh - 2px);
                    color: var(--font-mine);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    text-align: center;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input[type=number] {
                    -moz-appearance: textfield;
                }
            }
            .margin {
                border-bottom: 1px solid var(--border-main);
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;

                .item {
                    max-width: 45%;
                    width: 45%;
                    .select{
                        width: calc(100%);
                        .custom_icon{
                            padding: 0.4vh;
                            border-radius: 50%;
                            background-color: var(--input-dropdown-button);
                            @include flex(center,center);
                            path{
                                stroke: var(--font-mine);
                            }
                        }
                        .custom_option{
                            font-size:1.4vh;
                            line-height:1.6vh;
                            padding:0.8vh 0.4vh 0.8vh 0.8vh;
                            border-radius:32px;
                            position: relative;
                            .option{
                                display: inline-flex;
                                align-items: center;
                                justify-content: start;
                                width: auto;
                                padding: 0.6vh 0.8vh;
                                border-radius: 30px;
                                color: var(--font-mine);
                            }
                        }
                        .custom_option_selected{
                            font-size:1.4vh;
                            line-height:1.6vh;
                            padding:0.8vh 0.4vh 0.8vh 0.8vh;
                            border-radius:32px;
                            position: relative;
                            background-color:var(--input-bg) ;
                            .option{
                                display: inline-flex;
                                align-items: center;
                                justify-content: start;
                                width: auto;
                                padding: 0.6vh 0.8vh;
                                border-radius: 30px;
                                color: var(--font-mine);
                            }
                        }
                        .custom_option:hover{
                            background-color:var(--input-bg) ;
                            color: var(--font-mine);
                        }
                    }
                    label {
                        color: var(--font-sub-font, #51525C);
                        font-family: 'Inter';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.24px;
                    }

                    input {
                        box-sizing: border-box;
                    display: flex;
                    height: 34px;
                    padding: 8px 8px 8px 16px;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                    border-radius: var(--Corner-Full, 1000px);
                    border: 1px solid var(--border-main, #26272B);
                    background: var(--input-bg, #131316);
                    font-family: 'Inter';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    width: 100%;
                    margin-top: 5px;
                    }
                }
            }
            .label_input {
                width: 100%;
                @include flex(start, start);
                flex-direction: column;
                gap: 1.2vh;

                .label_balance {
                    width: 100%;
                    @include flex(center, space-between);

                    .balance {
                        color: var(--font-sub-font);
                        font-size: 1.2vh;
                        font-weight: 500;
                        line-height: 1.6vh;
                        letter-spacing: 0.24px;

                        .amount {
                            color: var(--other-purple);
                            text-decoration: underline;
                        }
                    }
                }

                label {
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }

                .volume_input {
                    border: 1px solid var(--border-main);
                    @include flex(center, center);
                    padding: 1.2vh;
                    border-radius: 1000px;
                    width: calc(100% - 2.4vh - 2px);
                    background: var(--input-bg);

                    span {
                        @include flex(center, center);

                        path {
                            stroke: var(--font-sub-font);
                        }

                        circle {
                            stroke: var(--font-sub-font);
                        }
                    }
                }

                input {
                    outline: none;
                    border-radius: 1000px;
                    border: none;
                    background: none;
                    font-family: $font;
                    width: calc(100% - 2.4vh - 2px);
                    color: var(--font-mine);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    text-align: center;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input[type=number] {
                    -moz-appearance: textfield;
                }
            }

            .label_input_new {
                width: 100%;
                @include flex(start, start);
                flex-direction: column;
                gap: 1.2vh;

                label {
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }

                input {
                    outline: none;
                    border-radius: 1000px;
                    border: none;
                    background: none;
                    font-family: $font;
                    padding: 1.4vh 0.8vh 1.4vh 1.6vh;
                    border: 1px solid var(--border-main);
                    background: var(--input-bg);
                    width: calc(100% - 2.4vh - 2px);
                    color: var(--font-mine);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input[type=number] {
                    -moz-appearance: textfield;
                }
            }

            .profit_and_loss {
                display: flex;
                justify-content: space-between;
                gap: 16px;
                width: 100%;

                .first_column {
                    display: flex;
                    flex-direction: column;
                    flex: 2;
                    max-width: 38%;
                    gap: 8px;

                    div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span {
                            color: var(--Font-Sub-Font, #51525C);
                            font-family: 'Inter';
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0.24px; 
                        }
                    }
                    .input_with_adort{
                        display: flex;
                        padding: 8px 8px 8px 16px;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1 0 0;
                        border-radius: var(--Corner-Full, 1000px);
                        border: 1px solid var(--border-main, #26272B);
                        background: var(--input-bg, #131316);
                        color: var(--font-mine, #FCFCFC);
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        input{
                            width: 100%;
                            border: none;
                            padding: 0;
                        }
                        .adort{
                            color: var(--font-mine, #FCFCFC);

                        }
                    }
                    input {
                        display: flex;
                        padding: 8px 8px 8px 16px;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1 0 0;
                        border-radius: var(--Corner-Full, 1000px);
                        border: 1px solid var(--border-main, #26272B);
                        background: var(--input-bg, #131316);
                        color: var(--font-mine, #FCFCFC);
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                    }

                    .switch {
                        position: relative;
                        display: inline-block;
                        width: 32px;
                        height: 20px;
                      }
                      
                      .switch input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                      }
                      
                      .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: var(--input-bg);
                        -webkit-transition: .4s;
                        transition: .4s;
                        border-radius: 20px;
                      }
                      
                      .slider:before {
                        position: absolute;
                        content: "";
                        height: 14px;
                        width: 14px;
                        left: 3px;
                        bottom: 3px;
                        background-color: var(--checkbox-before);
                        -webkit-transition: .4s;
                        transition: .4s;
                        border-radius: 50%;
                      }
                      
                      input:checked + .slider {
                        background: #2196F3;
                      }
                      
                      input:focus + .slider {
                        box-shadow: 0 0 1px #2196F3;
                      }
                      
                      input:checked + .slider:before {
                        background: #fcfcfc;
                      }
                      input:checked + .slider:before {
                        -webkit-transform: translateX(12px);
                        -ms-transform: translateX(12px);
                        transform: translateX(12px);
                      }
                      
                }

                .second_column {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    justify-content: end;
                    gap: 8px;

                    span {
                        color: var(--Font-Sub-Font, #51525C);
                        text-align: center;
                        font-family: 'Inter';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 34px;
                        letter-spacing: 0.24px;
                    }
                }

                .third_column {
                    display: flex;
                    flex-direction: column;
                    flex: 2;
                    max-width: 38%;
                    gap: 8px;

                    div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span {
                            color: var(--Font-Sub-Font, #51525C);
                            font-family: 'Inter';
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0.24px; 
                        }
                    }

                    .input_with_adort{
                        display: flex;
                        padding: 8px 8px 8px 16px;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1 0 0;
                        border-radius: var(--Corner-Full, 1000px);
                        border: 1px solid var(--border-main, #26272B);
                        background: var(--input-bg, #131316);
                        color: var(--font-mine, #FCFCFC);
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        input{
                            width: 100%;
                            border: none;
                            padding: 0;
                        }
                        .adort{
                            color: var(--font-mine, #FCFCFC);

                        }
                    }
                    input {
                        box-sizing: border-box;
                        display: flex;
                        padding: 8px 8px 8px 16px;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1 0 0;
                        border-radius: var(--Corner-Full, 1000px);
                        border: 1px solid var(--border-main, #26272B);
                        background: var(--input-bg, #131316);
                        color: var(--font-mine, #FCFCFC);
                        font-family: 'Inter';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                    }

                    .switch {
                        position: relative;
                        display: inline-block;
                        width: 32px;
                        height: 20px;
                      }
                      
                      .switch input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                      }
                      
                      .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: var(--input-bg);
                        -webkit-transition: .4s;
                        transition: .4s;
                        border-radius: 20px;
                      }
                      
                      .slider:before {
                        position: absolute;
                        content: "";
                        height: 14px;
                        width: 14px;
                        left: 3px;
                        bottom: 3px;
                        background-color: var(--checkbox-before);
                        -webkit-transition: .4s;
                        transition: .4s;
                        border-radius: 50%;
                      }
                      
                      input:checked + .slider {
                        background: #2196F3;
                      }
                      input:checked + .slider:before {
                        background: #fcfcfc;
                      }
                      
                      input:focus + .slider {
                        box-shadow: 0 0 1px #2196F3;
                      }
                      
                      input:checked + .slider:before {
                        -webkit-transform: translateX(12px);
                        -ms-transform: translateX(12px);
                        transform: translateX(12px);
                      }
                }
            }
            .select_block {
                border-top: 1px solid var(--border-main);
                padding-top: 20px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 4px;

                span {
                    color: var(--font-sub-font, #51525C);
                    font-family: 'Inter';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.24px;
                }

                select {
                    display: flex;
                    height: 48px;
                    padding: 8px 8px 8px 16px;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                    border-radius: var(--Corner-Full, 1000px);
                    border: 1px solid var(--border-main, #26272B);
                    background: var(--background-2, #18181B);
                    color: var(--font-mine, #FCFCFC);
                    font-family: 'Inter';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    width: 100%;

                    option {
                        width: 100%;
                    }
                }
            }
            .label_select {
                width: 100%;
                @include flex(start, start);
                flex-direction: column;
                gap: 1.2vh;

                label {
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }

                .select {
                    width: 100%;

                    .custom_icon {
                        padding: 0.8vh;
                        border-radius: 50%;
                        background-color: var(--input-dropdown-button);
                        @include flex(center, center);

                        path {
                            stroke: var(--font-mine);
                        }
                    }

                    .custom_option {
                        font-size: 1.4vh;
                        line-height: 1.6vh;
                        padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                        border-radius: 32px;
                        position: relative;

                        .option {
                            display: inline-flex;
                            align-items: center;
                            justify-content: start;
                            width: auto;
                            padding: 0.6vh 0.8vh;
                            border-radius: 30px;
                            color: var(--font-mine);
                        }
                    }

                    .custom_option_selected {
                        font-size: 1.4vh;
                        line-height: 1.6vh;
                        padding: 0.8vh 0.4vh 0.8vh 0.8vh;
                        border-radius: 32px;
                        position: relative;
                        background-color: var(--input-bg);

                        .option {
                            display: inline-flex;
                            align-items: center;
                            justify-content: start;
                            width: auto;
                            padding: 0.6vh 0.8vh;
                            border-radius: 30px;
                            color: var(--font-mine);
                        }
                    }

                    .custom_option:hover {
                        background-color: var(--input-bg);
                        color: var(--font-mine);
                    }
                }
            }
        }
    }

    .drawer_footer {
        width: calc(100% - 3.2vh);
        padding: 1.6vh;
        @include flex(center, center);
        gap: 0.8vh;
        border-top: 1px solid var(--border-main);

        div {
            flex: 1;
            @include flex(center, center);
            border-radius: 9999px;
            background: var(--buttons-alternative-default);
            color: var(--nav-button-font-active);
            font-size: 1.4vh;
            padding: 1.6vh;
            font-weight: 500;
            line-height: 1.6vh;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
        }

        div:hover {
            background: var(--buttons-alternative-hover);
        }

        button {
            flex: 1;
            @include flex(center, center);
            border-radius: 9999px;
            border: none;
            padding: 1.6vh;
            font-family: $font;
            color: var(--global-dark-25);
            font-size: 1.4vh;
            font-weight: 500;
            line-height: 1.6vh;
            background: var(--other-purple);
            cursor: pointer;
        }

        button:hover {
            transition: all 0.15s ease-in-out;
            background: var(--other-purple);
            box-shadow: 0px 0px 8px 2px rgba(105, 79, 214, 0.32);
        }
    }
}
@media (max-width: 600px){
    .paper{
        padding: 0;
        width: 100%;
    }
    .account_drawer {
        width: calc(100% - 2px);
        .close_icon {
            display: none;
        }
        .top_drawer{

            .close_icon_mobile{
                @include flex(center,center);
                padding: 12px;
                border-radius: 1000px;
                background-color: var(--background-3);
            }
        }
    }
}