:root {
    --background-1: var(--global-light-100);
    --background-2: #ffffff;
    --background-3: var(--global-dark-50);
    --border-main: var(--global-light-200);
    --buttons-alternative-default: var(--global-light-100);
    --buttons-alternative-hover: var(--global-light-200);
    --buttons-alternative-switcher: #ffffff;
    --font-mine: var(--global-light-800);
    --font-sub-font: var(--global-light-400);
    --font-sub-font-2: var(--global-light-400);
    --global-dark-25: #fcfcfc;
    --global-dark-400: #a0a0ab;
    --global-dark-50: #fafafa;
    --global-dark-600: #51525c;
    --global-dark-700: #3f3f46;
    --global-dark-800: #26272b;
    --global-dark-900: #18181b;
    --global-dark-925: #1d1d20;
    --global-dark-950: #131316;
    --global-light-100: #f3f4f6;
    --global-light-200: #e5e7eb;
    --global-light-25: #fcfcfd;
    --global-light-300: #d1d5db;
    --global-light-400: #9ca3af;
    --global-light-500: #6b7280;
    --global-light-700: #374151;
    --global-light-800: #1f2937;
    --graph-create-order: var(--global-light-700);
    --graph-line: #e5e7eb40;
    --graph-line-2: var(--global-light-400);
    --graph-price: var(--global-light-200);
    --input-bg: var(--global-light-100);
    --input-dropdown-button: #ffffff;
    --nav-button-active: var(--global-light-100);
    --nav-button-default: var(--global-light-25);
    --nav-button-font-active: var(--global-light-800);
    --nav-button-font-default: var(--global-light-500);
    --nav-button-hover: var(--global-light-100);
    --other-brand-main: #52a5e5;
    --other-green: #00b4a0;
    --other-orange: #e9770e;
    --other-purple: #a855f7;
    --other-red: #f63d68;
    --other-yellow: #e9b90e;
    --account-bg-real-svg:url('../imgs/real-light.svg');
    --account-bg-demo-svg:url('../imgs/demo-light.svg');
    --login-bg-svg:url('../imgs/login-bg-light.svg');
    --graph-toolbar-bg: #FCFCFD;
    --graph-toolbar-color: #374151;
    --checkbox-before: #D1D5DB;
}

[data-theme="dark"] {
    --background-1: var(--global-dark-950);
    --background-2: var(--global-dark-900);
    --background-3: var(--global-dark-925);
    --border-main: var(--global-dark-800);
    --buttons-alternative-default: var(--global-dark-925);
    --buttons-alternative-hover: var(--global-dark-800);
    --buttons-alternative-switcher: var(--global-dark-925);
    --font-mine: var(--global-dark-25);
    --font-sub-font: var(--global-dark-600);
    --font-sub-font-2: var(--global-dark-700);
    --global-dark-25: #fcfcfc;
    --global-dark-400: #a0a0ab;
    --global-dark-50: #fafafa;
    --global-dark-600: #51525c;
    --global-dark-700: #3f3f46;
    --global-dark-800: #26272b;
    --global-dark-900: #18181b;
    --global-dark-925: #1d1d20;
    --global-dark-950: #131316;
    --global-light-100: #f3f4f6;
    --global-light-200: #e5e7eb;
    --global-light-25: #fcfcfd;
    --global-light-300: #d1d5db;
    --global-light-400: #9ca3af;
    --global-light-500: #6b7280;
    --global-light-700: #374151;
    --global-light-800: #1f2937;
    --graph-create-order: var(--global-dark-800);
    --graph-line: #26272b40;
    --graph-line-2: var(--global-dark-700);
    --graph-price: var(--global-dark-700);
    --input-bg: var(--global-dark-950);
    --input-dropdown-button: var(--global-dark-800);
    --nav-button-active: var(--global-dark-925);
    --nav-button-default: var(--global-dark-900);
    --nav-button-font-active: var(--global-dark-25);
    --nav-button-font-default: var(--global-dark-600);
    --nav-button-hover: var(--global-dark-925);
    --other-brand-main: #52a5e5;
    --other-green: #00b4a0;
    --other-orange: #e9770e;
    --other-purple: #a855f7;
    --other-red: #f63d68;
    --other-yellow: #e9b90e;
    --account-bg-real-svg:url('../imgs/real.svg');
    --account-bg-demo-svg:url('../imgs/demo.svg');
    --login-bg-svg:url('../imgs/login-bg-dark.svg');
    --graph-toolbar-bg: #26272B;
    --graph-toolbar-color: #FCFCFC;
    --checkbox-before: #3f3f46;

}