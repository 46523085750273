body {
  margin: 0;
  font-family: 'Inter' , sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPaper-root{
  box-shadow: none !important;
  background: none !important;
}

#tradingview_chart_dark iframe {
  background-color: #ff0000 !important;
}