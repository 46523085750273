@import './vars.scss';
@import './theme.scss';

.paper {
    padding: 0;
}

.account_drawer {
    width: 100%;
    background-color: var(--background-2);
    position: relative;
    @include flex(center, start);
    flex-direction: column;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    height: 90vh;
    .top_drawer{
        padding: 16px;
        width: calc(100% - 32px);
        @include flex(center,space-between);
        border-bottom: 1px solid var(--border-main);
        .left{
            @include flex(center,start);
            gap: 12px;
            .icon{
                @include flex(center,center);
                padding: 8px;
                border-radius: 9999px;
                background: var(--border-main);
                path{
                    stroke: var(--font-mine);
                }
            }
            span{
                color: var(--font-mine);
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.28px;
            }
        }
        .close_icon{
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            border-radius: 9999px;
            background: var(--buttons-alternative-default);
        }
    }
    .drawer_footer{
        width: calc(100% - 3.2vh);
        padding: 1.6vh;
        @include flex(center,center);
        gap: 0.8vh;
        border-top: 1px solid var(--border-main);
        div{
            flex: 1;
            @include flex(center,center);
            border-radius: 9999px;
            background: var(--buttons-alternative-default);
            color: var(--nav-button-font-active);
            font-size: 14px;
            padding: 16px;
            font-weight: 500;
            line-height: 1.6vh;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
        }
        div:hover{
            background: var(--buttons-alternative-hover);
        }
    }
}

.select_search{
    width: calc(100% - 2.4vh);
    @include flex(center,start);
    flex-direction: column;
    gap: 1.2vh;
    padding: 1.2vh;
    .select{
        width: calc(100% - 2.4vh);
        .custom_icon{
            padding: 0.8vh;
            border-radius: 50%;
            background-color: var(--input-dropdown-button);
            @include flex(center,center);
            path{
                stroke: var(--font-mine);
            }
        }
        .custom_option{
            font-size:14px;
            line-height:1.6vh;
            padding:0.8vh 0.4vh 0.8vh 0.8vh;
            border-radius:32px;
            position: relative;
            .option{
                display: inline-flex;
                align-items: center;
                justify-content: start;
                width: auto;
                padding: 6px 8px;
                border-radius: 30px;
                color: var(--font-mine);
            }
        }
        .custom_option_selected{
            font-size:14px;
            line-height:1.6vh;
            padding:0.8vh 0.4vh 0.8vh 0.8vh;
            border-radius:32px;
            position: relative;
            background-color:var(--input-bg) ;
            .option{
                display: inline-flex;
                align-items: center;
                justify-content: start;
                width: auto;
                padding: 6px 8px;
                border-radius: 30px;
                color: var(--font-mine);
            }
        }
        .custom_option:hover{
            background-color:var(--input-bg) ;
            color: var(--font-mine);
        }
    }
    .search{
        width: calc(100% - 6.2vh);
        @include flex(center,start);
        border-radius: 1000px;
        border: 1px solid var(--border-main);
        background: var(--input-bg);
        padding: 1.3vh 1.6vh;
        gap: 0.8vh;
        svg{
            width: 16px;
            height: 16px;
        }
        circle{
            stroke: var(--font-sub-font);
        }
        path{
            stroke: var(--font-sub-font);
        }
        input{
            outline: none;
            background: none;
            border: none;
            color: var(--font-mine);
            font-size: 14px;
            font-family: $font;
            font-weight: 500;
            line-height: 16px;
        }
    }
}
.assets{
    width: 100%;
    @include flex(center,start);
    flex-direction: column;
    .assets_title{
        width: calc(100% - 2.4vh);
        padding-left: 1.2vh;
        padding-right: 1.2vh;
        @include flex(center,center);
        border-bottom: 1px solid var(--border-main);
        span{
            color: var(--font-sub-font);
            font-size: 12px;
            font-weight: 500;
            line-height: 1.6vh;
            letter-spacing: 0.24px;
        }
        .name{
            width: 30%;
            text-align: start;
            padding: 1.2vh;
            font-size: 12px;
        }
        .price{
            width: 30%;
            text-align: start;
            padding: 1.2vh;
            font-size: 12px;
        }
        .percent{
            text-align: end;
            padding: 1.2vh;
            width: 40%;
        }
    }
    .assets_body{
        @include flex(center,start);
        width: calc(100% - 2.4vh);
        flex-direction: column;
        padding: 1.2vh;
        max-height: 55vh;
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE и Edge */
    
        &::-webkit-scrollbar {
            width: 0;  /* Chrome, Safari и Opera */
            height: 0;
        }
        .asset{
            width: calc(100% - 0.8vh);
            padding: 0.4vh;
            @include flex(center,center);

            .title_icon{
                width: 35%;
                text-align: start;
                padding-left: 0;
                padding-right: 1.2vh;
                color: var(--font-mine, #FCFCFC);
                font-size: 14px;
                font-weight: 500;
                line-height: 1.6vh;
                @include flex(center,start);
                gap: 1.2vh;
                img{
                    width: 3.2vh;
                    height: 3.2vh;
                }
                span{
                    p{
                        margin: 0;
                        color: var(--font-sub-font, #51525C);
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px; /* 133.333% */
                        letter-spacing: 0.24px;
                    }
                }
            }
            .price{
                width: 30%;
                text-align: start;
                padding: 1.2vh;
                color: var(--font-mine, #FCFCFC);
                font-size: 14px;
                font-weight: 500;
                line-height: 1.6vh;
            }
            .percent_icon{
                text-align: end;
                padding: 1.2vh;
                padding-right: 0.8vh;
                width: 40%;
                @include flex(center,end);
                gap: 2.4vh;
                .percent{
                    color: #F63D68;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.6vh;
                    &.plus{
                        color: var(--Other-Green, #00B4A0);
                    }
                }
                svg{
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                }
            }

        }
        .asset:hover{
            transition: all 0.15s ease-in-out;
            border-radius: 9999px;
            background: var(--background-3);
        }
    }
}