@import '../../styles/theme.scss';
@import '../../styles/vars.scss';

.main {
    flex: 12;
    @include flex(stretch, start);
    flex-direction: column;
    flex-direction: column;
    gap: 0.8vh;

    .main_block {
        flex: 3.4;
        border: 1px solid var(--border-main);
        background: var(--background-2);
        border-radius: 32px;
        @include flex(stretch, stretch);
        flex-direction: column;
        max-height: 59vh;
        overflow: hidden;

        .block_title {
            width: calc(100% - 3.2vh);
            @include flex(center, space-between);
            padding: 1.6vh;
            border-bottom: 1px solid var(--border-main);

            .trading_orders {
                display: none;
            }

            .mobile_assets {
                display: none;

            }

            .leftside {
                @include flex(center, start);
                gap: 4.8vh;

                .active_name {
                    @include flex(center, start);
                    gap: 1.2vh;

                    .name {
                        @include flex(start, center);
                        flex-direction: column;

                        .subtitle {
                            color: var(--font-mine);
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16px;
                        }

                        .title {
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                            letter-spacing: 0.24px;
                        }
                    }
                }

                .active_values {
                    @include flex(center, start);

                    .price {
                        padding-left: 2.4vh;
                        padding-right: 2.4vh;
                        @include flex(start, center);
                        flex-direction: column;
                        color: var(--Other-Green, #00B4A0);
                        font-size: 1.4vh;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.6vh;

                        span {
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                            letter-spacing: 0.24px;
                        }
                    }

                    .day_changes {
                        padding-left: 2.4vh;
                        padding-right: 2.4vh;
                        @include flex(start, center);
                        flex-direction: column;
                        color: var(--Other-Green, #00B4A0);
                        font-size: 1.4vh;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.6vh;
                        border-right: 1px solid var(--border-main, #26272B);
                        border-left: 1px solid var(--border-main, #26272B);

                        span {
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                            letter-spacing: 0.24px;
                        }
                    }

                    .day_high {
                        padding-left: 2.4vh;
                        padding-right: 2.4vh;
                        @include flex(start, center);
                        flex-direction: column;
                        color: var(--font-mine);
                        font-size: 1.4vh;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.6vh;

                        span {
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                            letter-spacing: 0.24px;
                        }
                    }

                    .day_low {
                        padding-left: 2.4vh;
                        padding-right: 2.4vh;
                        @include flex(start, center);
                        flex-direction: column;
                        color: var(--font-mine);
                        font-size: 1.4vh;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.6vh;
                        border-left: 1px solid var(--border-main, #26272B);

                        span {
                            color: var(--font-sub-font);
                            font-size: 1.2vh;
                            font-weight: 500;
                            line-height: 1.6vh;
                            letter-spacing: 0.24px;
                        }
                    }
                }
            }

            .rightside {
                @include flex(center, end);
                gap: 1.6vh;
                .buy_sell {
                    text-align: center;
                    @include flex(center, center);
                    gap: 0.1vh;
    
                    button {
                        padding: 1.2vh;
                        @include flex(center, center);
                        font-family: $font;
                        border-radius: 8px 0px 0px 8px;
                        background: var(--graph-toolbar-bg);
                        border: none;
                        cursor: pointer;
                        font-size: 1.4vh;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.6vh;
    
                        span {
                            width: 10vh;
                        }
                    }
    
                    button:first-child {
                        border-top-left-radius: 1000px;
                        border-bottom-left-radius: 1000px;
                        color: #F63D68;
                        position: relative;
                        outline: none;
                        overflow: hidden;
    
                        .sell_gradient {
                            position: absolute;
                            left: 0;
                        }
                    }
    
                    button:last-child {
                        border-top-right-radius: 1000px;
                        border-bottom-right-radius: 1000px;
                        color: #00B4A0;
                        position: relative;
                        outline: none;
                        overflow: hidden;
    
                        .buy_gradient {
                            position: absolute;
                            left: 0;
                        }
                    }
                }
                .tabs {
                    position: relative;
                    background-color: #333;
                    border-radius: 8px;
                    padding: 0.2vh;
                    background-color: var(--input-bg);
                    gap: 0.4vh;
                    @include flex(center, start);
                    border-radius: 1000px;

                    .background {
                        position: absolute;
                        top: 0.2vh;
                        background-color: var(--buttons-alternative-switcher);
                        border-radius: 2.4vh;
                        transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
                        z-index: 0;
                    }
                }

                .tab {
                    @include flex(center, center);
                    flex: 1;
                    padding: 1vh 1.2vh;
                    gap: 0.8vh;
                    font-family: $font;
                    background-color: transparent;
                    border: none;
                    color: #b3b3b3;
                    font-weight: 500;
                    cursor: pointer;
                    transition: color 0.3s;
                    text-wrap: nowrap;
                    z-index: 1;

                    svg {
                        path {
                            color: var(--font-mine);
                        }

                        circle {
                            color: var(--font-mine);
                        }
                    }

                    &.active {
                        color: var(--font-mine);

                        svg {
                            path {
                                stroke: #FCFCFC;
                            }

                            circle {
                                stroke: #FCFCFC;
                            }
                        }
                    }

                    &:hover {
                        color: var(--font-mine);
                    }
                }

                .favorites {
                    @include flex(center, center);
                    padding: 1.2vh;
                    cursor: pointer;
                    border-radius: 9999px;
                    background: var(--graph-toolbar-bg);
                    box-shadow: 0px 4px 16px 0px rgba(19, 19, 22, 0.08);
                }
            }

        }
        .buy_sell_mobile{
            display: none;
        }
        .mobile_orders {
            display: none;
        }

        .block_graph {
            width: 100%;
            @include flex(stretch, stretch);
            height: 100%;
            flex: 2;
            position: relative;

            .tabs {
                display: none;
            }

            .buy_sell {
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                bottom: 0px;
                @include flex(center, center);
                gap: 0.1vh;

                button {
                    padding: 1.2vh;
                    @include flex(center, center);
                    font-family: $font;
                    border-radius: 8px 0px 0px 8px;
                    background: var(--graph-toolbar-bg);
                    border: none;
                    cursor: pointer;
                    font-size: 1.4vh;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.6vh;

                    span {
                        width: 10vh;
                    }
                }

                button:first-child {
                    border-top-left-radius: 1000px;
                    border-bottom-left-radius: 1000px;
                    color: #F63D68;
                    position: relative;
                    outline: none;
                    overflow: hidden;

                    .sell_gradient {
                        position: absolute;
                        left: 0;
                    }
                }

                button:last-child {
                    border-top-right-radius: 1000px;
                    border-bottom-right-radius: 1000px;
                    color: #00B4A0;
                    position: relative;
                    outline: none;
                    overflow: hidden;

                    .buy_gradient {
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }
    }

    .orders_block {
        flex: 1;
        border: 1px solid var(--border-main);
        background: var(--background-2);
        border-radius: 32px;
        outline: none;
        overflow: hidden;
        padding-bottom: 0;

        .orders_content {
            width: calc(100% - 3.2vh);
            @include flex(start, start);
            flex-direction: column;
            overflow-y: auto;
            padding: 1.6vh;
            .orders_top{
                @include flex(start,space-between);
                width: 100%;
                .tabs {
                    width: 54vh;
                    position: relative;
                    background-color: #333;
                    border-radius: 8px;
                    padding: 0.2vh;
                    background-color: var(--input-bg);
                    gap: 0.4vh;
                    @include flex(center, start);
                    border-radius: 1000px;
                }
    
                .tab {
                    width: 33%;
                    @include flex(center, center);
                    flex: 1;
                    padding: 1vh 1.2vh;
                    gap: 0.8vh;
                    font-family: $font;
                    background-color: transparent;
                    border: none;
                    color: #b3b3b3;
                    font-weight: 500;
                    cursor: pointer;
                    transition: color 0.3s;
                    text-wrap: nowrap;
                    z-index: 1;
    
                    svg {
                        path {
                            color: var(--font-mine);
                        }
    
                        circle {
                            color: var(--font-mine);
                        }
                    }
    
                    &.active {
                        color: var(--font-mine);
    
                        svg {
                            path {
                                stroke: #FCFCFC;
                            }
    
                            circle {
                                stroke: #FCFCFC;
                            }
                        }
                    }
    
                    &:hover {
                        color: var(--font-mine);
                    }
                }
    
                .background {
                    position: absolute;
                    top: 0.2vh;
                    background-color: var(--buttons-alternative-switcher);
                    border-radius: 2.4vh;
                    transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
                    z-index: 0;
                }
                .change_size{
                    @include flex(center,center);
                    padding: 0.8vh;
                    border-radius: var(--Corner-Full, 1000px);
                    background: var(--input-dropdown-button, #26272B);
                    border: none;
                    cursor: pointer;
                    &.rotate{
                        transition: transform 0.15s ease-in-out;
                        transform: rotate(180deg);
                    }
                }
            }

            .header_cell {
                display: flex;
                align-items: center;
                gap: 0.4vh;

                span {
                    color: var(--font-sub-font);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 2.4vh;
                    letter-spacing: 0.24px;
                }

                .header_name {
                    display: block !important;
                }

                .sort_icon {
                    @include flex(center, start);
                    flex-direction: column;
                    gap: 0.2vh;
                    padding: 0.3vh 0.5vh;

                    path {
                        stroke: var(--font-sub-font);
                    }
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 0.2vw;
        opacity: 0.5;
        /* Ширина скроллбара */
    }

    ::-webkit-scrollbar-track {
        background: transparent;
        /* Цвет дорожки скроллбара, темные тона */
    }

    ::-webkit-scrollbar-thumb {
        background: #3F3F46;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #777;
        /* Цвет ползунка при наведении, чуть светлее */
    }
}


@media (max-width:600px) {
    .main {
        width: 100%;
        justify-content: center;
        align-items: center;

        .main_block {
            width: 100%;
            flex: 4.4;
            height: 100%;
            max-height: 95vh;
            border: none;
            background: none;
            justify-content: center;
            align-items: center;

            .block_title {
                border: none;
                background: none;
                padding: 16px;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .trading_orders {
                    width: 100%;
                    position: relative;
                    background-color: #333;
                    border-radius: 8px;
                    padding: 0.2vh;
                    background-color: var(--input-bg);
                    gap: 0.2vh;
                    @include flex(center, start);
                    border-radius: 1000px;

                    .tab {
                        @include flex(center, center);
                        flex: 1;
                        padding: 1vh 1.2vh;
                        gap: 0.8vh;
                        font-family: $font;
                        background-color: transparent;
                        border: none;
                        color: #b3b3b3;
                        font-weight: 500;
                        cursor: pointer;
                        transition: color 0.3s;
                        text-wrap: nowrap;
                        z-index: 1;
                        font-size: 12px;

                        svg {
                            path {
                                color: var(--font-mine);
                            }

                            circle {
                                color: var(--font-mine);
                            }
                        }

                        &.active {
                            color: var(--font-mine);

                            svg {
                                path {
                                    stroke: #FCFCFC;
                                }

                                circle {
                                    stroke: #FCFCFC;
                                }
                            }
                        }

                        &:hover {
                            color: var(--font-mine);
                        }
                    }

                    .background {
                        position: absolute;
                        top: 0.2vh;
                        background-color: var(--buttons-alternative-switcher);
                        border-radius: 2.4vh;
                        transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
                        z-index: 0;
                    }
                }
                .buy_sell_mobile {
                    text-align: center;
                    @include flex(center, center);
                    margin-top: 1vh;
                    gap: 0.1vh;
    
                    button {
                        padding: 1.2vh;
                        @include flex(center, center);
                        font-family: $font;
                        border-radius: 8px 0px 0px 8px;
                        background: var(--graph-toolbar-bg);
                        border: none;
                        cursor: pointer;
                        font-size: 1.6vh;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.6vh;
    
                        span {
                            width: 10vh;
                        }
                    }
    
                    button:first-child {
                        border-top-left-radius: 1000px;
                        border-bottom-left-radius: 1000px;
                        color: #F63D68;
                        position: relative;
                        outline: none;
                        overflow: hidden;
    
                        .sell_gradient {
                            position: absolute;
                            left: 0;
                        }
                    }
    
                    button:last-child {
                        border-top-right-radius: 1000px;
                        border-bottom-right-radius: 1000px;
                        color: #00B4A0;
                        position: relative;
                        outline: none;
                        overflow: hidden;
    
                        .buy_gradient {
                            position: absolute;
                            left: 0;
                        }
                    }
                }
                .mobile_assets {
                    margin-top: 12px;
                    width: calc(100% - 24px);
                    padding: 12px;
                    @include flex(center, space-between);
                    border-radius: 9999px;
                    background: var(--background-2, #18181B);

                    .left {
                        @include flex(center, start);
                        gap: 8px;

                        img {
                            width: 32px;
                            height: 32px;
                        }

                        .title_price {
                            @include flex(start, center);
                            flex-direction: column;

                            .title {
                                color: var(--font-mine);
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 16px;
                            }

                            .price {
                                color: var(--other-green);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 16px;
                                letter-spacing: 0.24px;
                            }
                        }
                    }

                    .icon {
                        @include flex(center, center);
                        border-radius: 9999px;
                        background: var(--buttons-alternative-default);
                        padding: 12px;

                        path {
                            stroke: var(--font-mine);
                        }
                    }
                }

                .leftside {
                    display: none;
                }

                .rightside {
                    display: none;
                }
            }

            .mobile_orders {
                width: 100%;
                max-width: 340px;
                @include flex(center, start);
                flex-direction: column;
                height: 100%;
                flex: 2;
                position: relative;

                .search {
                    width: calc(100% - 6.2vh);
                    @include flex(center, start);
                    border-radius: 1000px;
                    border: 1px solid var(--border-main);
                    background: var(--input-bg);
                    padding: 1.3vh 1.6vh;
                    gap: 0.8vh;

                    svg {
                        width: 16px;
                        height: 16px;
                    }

                    circle {
                        stroke: var(--font-sub-font);
                    }

                    path {
                        stroke: var(--font-sub-font);
                    }

                    input {
                        outline: none;
                        background: none;
                        border: none;
                        color: var(--font-mine);
                        font-size: 14px;
                        font-family: $font;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }

                .mobile_orders_tabs {
                    margin-top: 12px;
                    width: 100%;
                    position: relative;
                    background-color: #333;
                    border-radius: 8px;
                    padding: 0.2vh;
                    background-color: var(--input-bg);
                    gap: 0.4vh;
                    @include flex(center, start);
                    border-radius: 1000px;
                }

                .tab {
                    @include flex(center, center);
                    flex: 1;
                    padding: 1vh 1.2vh;
                    gap: 0.8vh;
                    font-family: $font;
                    background-color: transparent;
                    border: none;
                    color: #b3b3b3;
                    font-weight: 500;
                    cursor: pointer;
                    transition: color 0.3s;
                    text-wrap: nowrap;
                    z-index: 1;

                    svg {
                        path {
                            color: var(--font-mine);
                        }

                        circle {
                            color: var(--font-mine);
                        }
                    }

                    &.active {
                        color: var(--font-mine);

                        svg {
                            path {
                                stroke: #FCFCFC;
                            }

                            circle {
                                stroke: #FCFCFC;
                            }
                        }
                    }

                    &:hover {
                        color: var(--font-mine);
                    }
                }

                .background {
                    position: absolute;
                    top: 0.2vh;
                    background-color: var(--buttons-alternative-switcher);
                    border-radius: 2.4vh;
                    transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
                    z-index: 0;
                }


                .transactions_list {
                    margin-top: 12px;
                    width: 100%;
                    @include flex(center, start);
                    flex-direction: column;
                    gap: 8px;
                    max-height: 70vh;
                    overflow-y: auto;
                    .transaction {
                        width: calc(100% - 48px);
                        padding: 24px;
                        @include flex(center, start);
                        flex-direction: column;
                        border-radius: 32px;
                        background: var(--background-2, #18181B);
                        gap: 16px;

                        hr {
                            width: 100%;
                            border: none;
                            border-bottom: 1px solid var(--border-main);
                        }

                        .type_status {
                            width: 100%;
                            @include flex(center, space-between);

                            .left_side {
                                @include flex(center, center);
                                gap: 8px;
                                color: var(--font-mine, #FCFCFC);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;

                                img {
                                    width: 24px;
                                    height: 24px;
                                }

                                .type {
                                    @include flex(center, center);
                                    padding: 4px 12px;
                                    border-radius: 9999px;
                                    background: rgba(246, 61, 104, 0.08);
                                    color: var(--Other-Red, #F63D68);
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 16px;
                                    letter-spacing: 0.24px;

                                    &.buy {
                                        color: var(--Other-Green, #00B4A0);
                                        background: rgba(0, 180, 160, 0.08);
                                    }

                                    &.sell {
                                        background: rgba(246, 61, 104, 0.08);
                                        color: var(--Other-Red, #F63D68);
                                    }

                                    &.simulate {
                                        background: rgba(233, 119, 14, 0.08);
                                        color: #E9770E;
                                    }
                                }

                                .leverage {
                                    @include flex(center, center);
                                    padding: 4px 12px;
                                    border-radius: 9999px;
                                    background: rgba(233, 119, 14, 0.08);
                                    color: #E9770E;
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 16px;
                                    letter-spacing: 0.24px;
                                    text-transform: uppercase;
                                }
                            }

                            .right_side {
                                @include flex(center, center);
                                gap: 8px;

                                .type {
                                    @include flex(center, center);
                                    padding: 4px 12px;
                                    border-radius: 9999px;
                                    background: rgba(246, 61, 104, 0.08);
                                    color: var(--Other-Red, #F63D68);
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 16px;
                                    letter-spacing: 0.24px;

                                    &.buy {
                                        color: var(--Other-Green, #00B4A0);
                                        background: rgba(0, 180, 160, 0.08);
                                    }

                                    &.sell {
                                        background: rgba(246, 61, 104, 0.08);
                                        color: var(--Other-Red, #F63D68);
                                    }

                                    &.simulate {
                                        background: rgba(233, 119, 14, 0.08);
                                        color: #E9770E;
                                    }
                                }

                                .leverage {
                                    @include flex(center, center);
                                    padding: 4px 12px;
                                    border-radius: 9999px;
                                    background: rgba(233, 119, 14, 0.08);
                                    color: #E9770E;
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 16px;
                                    letter-spacing: 0.24px;
                                    text-transform: uppercase;
                                }

                            }
                        }

                        .account_amount {
                            width: 100%;
                            @include flex(center, space-between);

                            .account {
                                @include flex(start, start);
                                flex-direction: column;
                                gap: 4px;

                                label {
                                    color: var(--font-sub-font, #51525C);
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 16px;
                                }

                                p {
                                    color: var(--font-mine, #FCFCFC);
                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 16px;
                                    margin: 0;
                                }

                                .profit_loss {
                                    @include flex(center, start);
                                    gap: 8px;
                                    color: var(--dark-700);
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 16px;
                                    letter-spacing: -0.26px;

                                    .take_profit {
                                        color: var(--Green, #00B4A0);
                                        font-size: 12px;
                                        font-weight: 600;
                                        line-height: 16px;
                                        letter-spacing: -0.26px;
                                    }

                                    .stop_loss {
                                        color: #F63D68;
                                        font-size: 12px;
                                        font-weight: 600;
                                        line-height: 16px;
                                        letter-spacing: -0.26px;
                                    }
                                }
                            }

                            .amount {
                                @include flex(end, start);
                                flex-direction: column;
                                gap: 4px;

                                label {
                                    color: var(--font-sub-font, #51525C);
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 16px;
                                }

                                p {
                                    @include flex(center, center);
                                    gap: 4px;
                                    color: var(--Other-Green, #00B4A0);
                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 16px;
                                    margin: 0;

                                    &.with {
                                        color: #E9770E;
                                    }
                                }
                            }
                        }

                        .id_date {
                            width: 100%;
                            @include flex(center, space-between);

                            .id,
                            .date {
                                color: var(--font-mine, #FCFCFC);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 16px;
                                margin: 0;
                            }
                        }
                    }

                }

            }

            .block_graph {
                width: 100%;

                .tabs {
                    overflow-x: auto;
                    width: 300px;
                    position: absolute;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    text-align: center;
                    bottom: 10px;
                    background-color: #333;
                    border-radius: 8px;
                    padding: 0.2vh;
                    background-color: var(--input-bg);
                    gap: 0.2vh;
                    @include flex(center, start);
                    display: flex !important;
                    border-radius: 1000px;
                }

                ::-webkit-scrollbar {
                    width: 0.1vw;
                    height: 0.1vw;
                    opacity: 0.5;
                    /* Ширина скроллбара */
                }
            
                ::-webkit-scrollbar-track {
                    background: transparent;
                    /* Цвет дорожки скроллбара, темные тона */
                }
            
                ::-webkit-scrollbar-thumb {
                    background: #3F3F46;
                    border-radius: 10px;
                }
            
                ::-webkit-scrollbar-thumb:hover {
                    background: #777;
                    /* Цвет ползунка при наведении, чуть светлее */
                }
                .tab {
                    @include flex(center, center);
                    flex: 1;
                    padding: 1vh 1.2vh;
                    gap: 0.8vh;
                    font-family: $font;
                    background-color: transparent;
                    border: none;
                    color: #b3b3b3;
                    font-weight: 500;
                    cursor: pointer;
                    transition: color 0.3s;
                    text-wrap: nowrap;
                    z-index: 1;
                    font-size: 12px;

                    svg {
                        path {
                            color: var(--font-mine);
                        }

                        circle {
                            color: var(--font-mine);
                        }
                    }

                    &.active {
                        color: var(--font-mine);

                        svg {
                            path {
                                stroke: #FCFCFC;
                            }

                            circle {
                                stroke: #FCFCFC;
                            }
                        }
                    }

                    &:hover {
                        color: var(--font-mine);
                    }
                }

                .background {
                    position: absolute;
                    top: 0.2vh;
                    background-color: var(--buttons-alternative-switcher);
                    border-radius: 2.4vh;
                    transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
                    z-index: 0;
                }
            }

            .buy_sell {
                bottom: 50px !important;

                button {
                    span {

                        font-size: 14px;
                    }

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

        }

        .orders_block {
            display: none;
        }
    }
}