@import './theme.scss';
@import './vars.scss';

.layout{
    padding: 1.6vh;
    width: calc(100% - 3.2vh);
    @include flex(stretch,stretch);
    flex-direction: column;
    min-height: 96.8vh;
    gap: 1.6vh;
    background: var(--background-1, #131316);
    .page{
        flex: 12;
        @include flex(stretch,stretch);
        gap: 1.6vh;
    }
}